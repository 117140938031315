import React , { Component } from "react"
import Link from 'gatsby-link'
import { withPrefix } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import axios from 'axios';

import Banner from "../components/banner-inner"
import steering from "../images/steering.png";


import {API_EN, BASE_URL } from '../utils/config';

import Loader from '../components/loader';

import ServiceBlock from '../components/services';
import ServiceDetails from '../components/service-details';

import Popup from "reactjs-popup";

import "../styles/page.scss"

class Services extends Component {
    _isMounted = false;
    constructor(props){
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.fetchServices = this.fetchServices.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.state = {
            data : null,
            loading : true,
            servicesData : null,
            modalOpen: false,
            modalData: null
        }
    }
    componentDidMount(){
        this._isMounted = true;
        const service = this.props.location.hash;
        service === '' ? this._isMounted && this.fetchData(['services']) : this._isMounted && this.fetchServices(service);
        //this.fetchData(['services']);
    }
    componentWillUnmount() {
        this._isMounted = false;
        // this.setState({
        //     data: null,
        //     loading : true,
        //     servicesData: null
        // })
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: true,
            data: null,
            servicesData : null
        })

        if(nextProps.location.hash == ''){
            this.fetchData(['services']);
        }

        if(nextProps.location.hash != ''){
            this.fetchServices(nextProps.location.hash);
        }


        
        // this.fetchData(['services'])
        // this._isMounted = true;
        // const service = this.props.location.hash;
        // service === '' ? this._isMounted && this.fetchData(['services']) : this._isMounted && this.fetchServices(service);
    }
    fetchServices(service){
        if( service.charAt( 0 ) === '#' ){
            service = service.slice( 1 );
        }

        const get =  axios.get(`${API_EN}/services/${service}`)
        .then((response) => {
            if(response.data.message) {
                this.props.navigate(withPrefix(`/404`));
            }else{
                if (this._isMounted) {
                    this.setState({
                        data : response.data.data,
                        loading : false   
                    })
                }
            }
            //return response
        });

        //console.log(get)
    }
     fetchData(pages){
        const get = endpoint => axios.get(`${API_EN}/${endpoint}`);
        
        return Promise.all(
            pages.map(async page => {
                const { data: item } = await get(`${page}`);
                const Data = {};
                Data.title = item.data[0].post_type;
                Data.services = item.data;
                return Data;  
            })
        ).then(res => {
            if (this._isMounted) {
                this.setState({
                    data : res,
                    loading : false   
                })
            }
        }); 
    }

    handleClick(e){
        e.preventDefault();

        //console.log(e)
        //this.fetchServices(this.props.)
    }

    openModal(data) {
        this.setState({ 
            modalOpen: true,
            modalData: data 
        });
    }
    closeModal() {
        this.setState({ 
            modalOpen: false,
            modalData: null 
        });
    }


    render(){
        // const _hash = this.props.location.hash;
        // var services, title;
        // if(!this.state.data) return false;
        
        // if(_hash && this.state.data.data === undefined){
        //     this.props.navigate('/404');
        //     return false;
        // }
        // if(_hash){
        //     const service = this.state.data.data;
        //     title = service.post_title;
        //     services = service;
        // }else{
        //     console.log(this.state)
        //     services = this.state.data[0].services;
        //     title = this.state.data[0].title;
        // }  
        
        const _hash = this.props.location.hash;
        const title = this.state.data && this.props.location.hash == '' ? this.state.data[0].title : this.state.data ? this.state.data.post_title: ''; 
        const services = this.state.data;
        //console.log(this.state.data.post_title, this.props.location.hash)
        return (
            <>  
                {this.state.loading ?
                <Loader/> :
                <Layout>
                    <SEO title={title} />
                    <Banner title={title} />
                    <section className={`page-section ${_hash === '' ? 'section-services' : 'section-service__details'}`}>
                        <div className="container">
                            <div className="section-block">
                                <div className="line __right"></div>
                                <figure className="placeholder"><img src={steering}/></figure>
                                

                                {_hash === '' ? <ServiceBlock services={services} /> : <ServiceDetails services={services} handleClick={this.openModal}/>}
                                
                                {/* <div className="services-wrap">
                                    {
                                        services.map((service, index) => 
                                            <div className="services-block" key={service.post_id}> 
                                                <Link to={`/services/#${service.post_id}`} >
                                                <div className="services-inner">
                                                    <div className="services-img">
                                                        <figure>
                                                            <div className="img" style={{ backgroundImage: `url(${service.service_image})` }}></div> 
                                                        </figure>
                                                        <div className="title"><span>{service.post_title}</span></div>
                                                    </div>
                                                    <div className="services-overlay">   
                                                        <div>
                                                            <div className="title"><span>{service.post_title}</span></div>
                                                            <div className="text" dangerouslySetInnerHTML={ {__html: service.description} }></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>
                                        )
                                    }
                                </div> */}
                            </div>
                        </div>
                    </section>
                    {this.state.modalData ? 
                        <Popup open={this.state.modalOpen} className={this.state.modalOpen ? 'show' : ''} closeOnDocumentClick onClose={this.closeModal}>
                            <div className="modal modal-services">
                                <div className="modal-img">
                                    <img src={this.state.modalData.sub_service_image}/>
                                </div>
                                <div className="modal-text">
                                    <h4 className="modal-title">{ this.state.modalData.post_title }</h4>
                                    <div className="text" dangerouslySetInnerHTML={ {__html: this.state.modalData.description} }></div>
                                </div>
                                <a className="modal-close" onClick={this.closeModal}><i className="icon icon-icons-close"></i></a> 
                            </div>
                        </Popup>
                        : null 
                    }
                </Layout>
                }
            </>
        )
    }
}

export default Services
