import React from "react"
import { Link } from "gatsby"

const ServiceDetails= (props) => {
    const services = props.services;
    return(
        <>
            <div className="service-info">
                <div className="text" dangerouslySetInnerHTML={ {__html: services.description} }>
                    
                </div>
                <div className="image">
                    <figure>
                        <div className="img" style={{ backgroundImage: `url(${services.service_image})` }}></div> 
                    </figure>
                </div>
            </div>
            { services.subServices ? 
            <div className="services-wrap">
                {
                    services.subServices.map((service, index) => 
                        <div className="services-block" key={service.post_id}>
                            {/* <Link to={`/services/#${service.post_id}`} state={{ postId: service.post_id }}>  */}
                            <a onClick={() => props.handleClick(service)} > 
                                <div className="services-inner">
                                    <div className="services-img">
                                        <figure>
                                            <div className="img" style={{ backgroundImage: `url(${service.sub_service_image})` }}></div> 
                                        </figure>
                                        <div className="title"><span>{service.post_title}</span></div>
                                    </div>
                                </div>
                            </a>
                            {/* </Link> */}
                        </div>
                    )
                }
            </div> : ''
            }
        </>
    );
}
export default ServiceDetails;