import React from "react"
import { Link } from "gatsby"

const Services = (props) => {
    const services = props.services[0].services;
    return(
        <div className="services-wrap">
            {
                services.map((service, index) => 
                    <div className="services-block" key={service.post_id}> 
                        <Link to={`/services/#${service.post_id}`} state={{ postId: service.post_id }}> 
                            <div className="services-inner">
                                <div className="services-img">
                                    <figure>
                                        <div className="img" style={{ backgroundImage: `url(${service.service_image})` }}></div> 
                                    </figure>
                                    <div className="title"><span>{service.post_title}</span></div>
                                </div>
                                <div className="services-overlay">   
                                    <div>
                                        <div className="title"><span>{service.post_title}</span></div>
                                        <div className="text" dangerouslySetInnerHTML={ {__html: service.description} }></div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
        </div>
    );
}
export default Services;